import React from 'react';
import clients from "../../assets/clients logos.png";
export default function Clients() {
  return (
    <div className='bg-port-green flex flex-wrap justify-center'>

            <img src={clients} className='w-full' alt="IN Marketing clients" />


    </div>
  )
}
